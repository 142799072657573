import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./Login";
import { NotFoundPage } from "./NotFound";
import { AuthPage } from "./Auth";
import { AppAuthLayout } from "./AppAuthLayout";

import { ManosMaestrasReport } from "./reports/ManosMaestras";
import { BimaxReport } from "./reports/Bimax";
import { MaperReport } from "./reports/Maper";
import { ServirefriaireReport } from "./reports/Servirefriaire";
import { PandoraReport } from "./reports/Pandora";
import { APHBogotaReport } from "./reports/APHBogota";
import { APHCentroOrienteReport } from "./reports/APHCentroOriente";
import { APHNorteReport } from "./reports/APHNorte";
import { APHTestReport } from "./reports/APHTest";
import { ServirefriaireImport } from "./imports/Servirefriaire";
import { BomberosSabanetaReport } from "./reports/BomberosSabaneta";
import { APHSurOccidenteReport } from "./reports/APHSurOccidente";
import { MatrixIndicators } from "./reports/Matrix";
import { CruzRojaAntioquiaReport } from "./reports/CruzRojaAntioquia";
import { NetuxReport } from "./reports/Netux";
import { BomberosRionegroReport } from "./reports/BomberosRionegro";
import { BomberosEnvigadoReport } from "./reports/BomberosEnvigado";
import { BomberosEnvigadoSaludMental } from "./reports/BomberosEnvigado/BomberosEnvigadoSaludMental";
import { BomberosEnvigadoBilling } from "./reports/BomberosEnvigado/BomberosEnvigadoBilling";
import { GrupoDeApoyoReport } from "./reports/GrupoDeApoyo";
import { SEMBelloReport } from "./reports/SEMBello";
import { DeltaForce } from "./reports/DeltaForce";
import { BomberosPeñol } from "./reports/BomberosPeñol";
import { BomberosUrrao } from "./reports/BomberosUrrao";
import { BomberosSanPedro } from "./reports/BomberosSanPedro";
import { BomberosCali } from "./reports/BomberosCali";
import { SosSalud } from "./reports/SosSalud";
import { AmbulanciasAvis } from "./reports/AmbulanciasAvis";

export const RoutesApp = () => (
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="auth" element={<AuthPage />} />
    <Route path="import-reports" element={<AppAuthLayout />}>
      <Route path="servirefriaire" element={<ServirefriaireImport />} />
    </Route>
    <Route path="export-reports" element={<AppAuthLayout />}>
      <Route path="manosmaestras" element={<ManosMaestrasReport />} />
      <Route path="bimax" element={<BimaxReport />} />
      <Route path="maper" element={<MaperReport />} />
      <Route path="natuxrepuestos" element={<NetuxReport />} />
      <Route path="servirefriaire" element={<ServirefriaireReport />} />
      <Route path="pandora" element={<PandoraReport />} />
      <Route path="aphbogota" element={<APHBogotaReport />} />
      <Route path="aphcentrooriente" element={<APHCentroOrienteReport />} />
      <Route path="aphnorte" element={<APHNorteReport />} />
      <Route path="aphtest" element={<APHTestReport />} />
      <Route path="bomberossabaneta" element={<BomberosSabanetaReport />} />
      <Route path="bomberosrionegro" element={<BomberosRionegroReport />} />
      <Route path="bomberosenvigado" element={<BomberosEnvigadoReport />} />
      <Route path="bomberosenvigadosm" element={<BomberosEnvigadoSaludMental />} />
      <Route path="bomberosenvigadobilling" element={<BomberosEnvigadoBilling />} />
      <Route path="aphsuroccidente" element={<APHSurOccidenteReport />} />
      <Route path="cruzrojaantioquia" element={<CruzRojaAntioquiaReport />} />
      <Route path="matrixindicadores" element={<MatrixIndicators />} />
      <Route path="grupodeapoyo" element={<GrupoDeApoyoReport />} />
      <Route path="sembello" element={<SEMBelloReport />} />
      <Route path="deltaforce" element={<DeltaForce />} />
      <Route path="bomberospe%C3%B1ol" element={<BomberosPeñol />} /> {/* %C3%B1 --> ñ */}
      <Route path="bomberosurrao" element={<BomberosUrrao />} /> 
      <Route path="bomberossanpedro" element={<BomberosSanPedro />} /> 
      <Route path="bomberoscali" element={<BomberosCali />} /> 
      <Route path="sossalud" element={<SosSalud />} /> 
      <Route path="ambulanciasavis" element={<AmbulanciasAvis />} /> 
    </Route>
    <Route path="*" element={<NotFoundPage />}></Route>
  </Routes>
);
